<template>
  <div class="download">
    <div
      :class="[
        'font-main',
        tag == '1' || tag == '2' || tag == '3' || tag == '4'
          ? 'download-Atitle'
          : 'download-Btitle',
      ]"
    >
      {{ titleName }}
    </div>
    <div class="download-amount">
      <el-select
        v-if="tag == '7'"
        placeholder="请选择排名"
        clearable
        v-model="inviteTpye"
        @change="doTpyeChange"
      >
        <el-option label="按邀请人数排序" value="1"></el-option>
        <el-option label="按消费金额排序" value="2"></el-option>
      </el-select>
      <el-date-picker
        v-else-if="tag == '1' || tag == '2' || tag == '3' || tag == '4'"
        v-model="aheadBuyUserTime"
        type="daterange"
        placeholder="选择日期时间"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="doChange"
      >
      </el-date-picker>

      <el-select
        v-if="tag == '5' || tag == '6' || tag == '7'"
        placeholder="请选择排名"
        clearable
        v-model="amount"
        @change="doValChange"
      >
        <el-option label="前10名" value="10"></el-option>
        <el-option label="前50名" value="50"></el-option>
        <el-option label="前100名" value="100"></el-option>
      </el-select>

      <el-button size="mini" type="primary" round @click="handleDownloadType"
        >导出数据</el-button
      >
    </div>
  </div>
</template>

<script>
import { DownloadBuyNum } from "@/api/module/dashboard";
import axios from "axios";
import { postExport } from "@/api/config";

export default {
  name: "SearchHead",
  props: ["tag"],
  data() {
    return {
      titleName: "",
      aheadBuyUserTime: [],
      amount: "",
      inviteTpye: "",
    };
  },
  created() {
    this.handleTitle();
  },
  methods: {
    handleDownloadType() {
      if (this.tag == "5" || this.tag == "6" || this.tag == "7") {
        this.handleDownloadTable();
      } else {
        this.handleDownloadChart();
      }
    },
    doTpyeChange(val) {
      this.$emit("updateInvite", val, this.amount);
    },
    doValChange(val) {
      if (this.tag == "7") {
        this.$emit("updateInvite", this.inviteTpye, val);
      } else {
        this.$emit("updateVal", val);
      }
    },
    doChange(val) {
      this.$emit("update", val);
    },
    async handleDownload() {
      let size;
      const r = await DownloadBuyNum(size);
      console.log(r);
    },
    selectVal() {
      let size, url, sortType;
      !this.amount ? (size = 10) : (size = this.amount);
      !this.inviteTpye ? (sortType = 10) : (sortType = this.inviteTpye);
      if (this.tag === "5") {
        url = `v1/dashBoard/buyNumList/download?size=${size}`;
      } else if (this.tag === "6") {
        url = `v1/dashBoard/buyAmountList/download?size=${size}`;
      } else if (this.tag === "7") {
        url = `v1/dashBoard/inviteList/download?size=${size}&sortType=${sortType}`;
      }
      return url;
    },
    handleDownloadTable() {
      const url = this.selectVal();
      axios({
        method: "get",
        url,
        responseType: "blob",
      })
        .then((res) => {
          let data = res.data;
          if (!data) {
            return;
          }
          let url = window.URL.createObjectURL(new Blob([data]));
          let a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.setAttribute("download", "excel.xls");
          document.body.appendChild(a);
          a.click(); //执行下载
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleDownloadChart() {
      let odata = {
        startTime: this.aheadBuyUserTime[0],
        endTime: this.aheadBuyUserTime[1],
      };
      if (this.tag === "1") {
        odata.tag = "1";
      } else if (this.tag === "2") {
        odata.tag = "2";
      } else if (this.tag === "3") {
        odata.tag = "3";
      } else if (this.tag === "4") {
        odata.tag = "4";
      }
      const data = await postExport("v1/dashBoard/tag/download", odata);
      if (!data) {
        return;
      }
      console.log(data);
      let url = window.URL.createObjectURL(new Blob([data]));
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", "excel.xls");
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
    handleTitle() {
      if (this.tag === "1") {
        this.titleName = "日注册/实名用户";
      } else if (this.tag === "2") {
        this.titleName = "月注册/实名用户";
      } else if (this.tag === "3") {
        this.titleName = "日销售数量/金额";
      } else if (this.tag === "4") {
        this.titleName = "月销售数量/金额";
      } else if (this.tag === "5") {
        this.titleName = "用户购买数量排行榜";
      } else if (this.tag === "6") {
        this.titleName = "用户消费金额量排行榜";
      } else if (this.tag === "7") {
        this.titleName = "用户邀请排行榜";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.download {
  height: 80px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-Atitle {
    font-family: "STKaiti", Georgia, Serif;
    font-weight: 700;
    margin-top: 10px;
    font-size: 20px;
  }
  &-Btitle {
    font-weight: 700;
    font-size: 14px;
  }
  /deep/ .el-input__inner {
    width: 240px;
    margin-right: 13px;
  }
  /deep/ .el-select {
    .el-input__inner {
      width: 160px;
    }
    .el-input__suffix {
      right: 20px;
    }
  }
}
</style>