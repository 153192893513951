<template>
  <div class="x-warp">
    <main class="x-main">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="数据总览" name="first">
          <div class="x-box">
            <div class="x-box-left">
              <div>
                <span>累计注册用户</span>
                <countTo
                  :startVal="baseNum"
                  :endVal="baseInfo.newHeightRegister"
                  :duration="3000"
                ></countTo>
                <span>今日新增 {{ baseInfo.todayRegister }}</span>
              </div>
              <div>
                <span>累计实名用户</span>
                <countTo
                  :startVal="baseNum"
                  :endVal="baseInfo.newHeightAuth"
                  :duration="3000"
                ></countTo>
                <span>今日新增 {{ baseInfo.todayAuth }}</span>
              </div>
              <div>
                <span>累计销售数量</span>
                <countTo
                  :startVal="baseNum"
                  :endVal="baseInfo.newHeightSalesNum"
                  :duration="3000"
                ></countTo>
                <span>今日新增 {{ baseInfo.todaySalesNum }}</span>
              </div>
              <div>
                <span>累计销售金额</span>
                <countTo
                  :startVal="baseNum"
                  :endVal="baseInfo.newHeightSalesAmount"
                  :duration="3000"
                  :decimals="2"
                ></countTo>
                <span>今日新增 {{ baseInfo.todaySalesAmount }}</span>
              </div>
            </div>
            <div class="x-box-right">
              <DayUser />
              <MonthUser />
              <DaySale />
              <MonthSale />
            </div>
          </div>

          <div class="x-box-bottom">
            <Num />
            <Amount />
            <Invite />
          </div>
        </el-tab-pane>
      </el-tabs>
    </main>
  </div>
</template>


<script>
import { BaseQuery } from "@/api/module/dashboard";
import DaySale from "./components/DaySale";
import DayUser from "./components/DayUser";
import MonthSale from "./components/MonthSale";
import MonthUser from "./components/MonthUser";
import Amount from "./components/Amount.vue";
import Num from "./components/Num.vue";
import Invite from "./components/Invite.vue";
import countTo from "vue-count-to";
import { testlog } from "@/api/module/app";

export default {
  name: "Dashboard",
  components: {
    DaySale,
    DayUser,
    MonthSale,
    MonthUser,
    countTo,
    Invite,
    Num,
    Amount,
  },
  data() {
    return {
      activeName: "first",
      baseNum: 0,
      baseInfo: {
        newHeightRegister: "",
        newHeightAuth: "",
        newHeightSalesAmount: "",
        newHeightSalesNum: "",
        todayRegister: "",
        todayAuth: "",
        todaySalesAmount: "",
        todaySalesNum: "",
      },
    };
  },
  created() {
    this.getBaseInfo();
    testlog();
  },
  methods: {
    async getBaseInfo() {
      const r = await BaseQuery();
      this.baseInfo = {
        newHeightRegister: Number(r.registerNum.total),
        newHeightAuth: Number(r.authNum.total),
        newHeightSalesAmount: Number(r.salesAmount.total),
        newHeightSalesNum: Number(r.salesNum.total),
        todayRegister: r.registerNum.today,
        todayAuth: r.authNum.today,
        todaySalesAmount: r.salesAmount.today,
        todaySalesNum: r.salesNum.today,
      };
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>