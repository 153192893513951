<template>
  <div class="component">
    <SearchHead :tag="tagName" @updateInvite="doQuery" />
    <el-table :data="tabalData" stripe style="width: 100%">
      <el-table-column type="index" label="排名" width="130"> </el-table-column>
      <el-table-column prop="phoneNumber" label="手机号码" width="150">
      </el-table-column>
      <el-table-column
        prop="inviteCount"
        label="邀请人数"
        width="140"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="inviteAmount"
        label="消费金额（/元）"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { InviteQuery } from "@/api/module/dashboard";
import SearchHead from "./SearchHead.vue";

export default {
  name: "Invite",
  data() {
    return {
      tagName: "7",
      tabalData: [],
    };
  },
  components: {
    SearchHead,
  },
  created() {},
  mounted() {
    this.doQuery();
  },

  methods: {
    async doQuery(val, size) {
      !val ? (val = 1) : val;
      !size ? (size = 10) : size;
      const r = await InviteQuery(size, val);
      this.tabalData = r;
    },
  },
};
</script>

<style lang="less" scoped>
.component {
  overflow: hidden;
  .el-table {
    padding: 0 20px 15px;
  }
}
</style>