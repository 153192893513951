<template>
  <div class="component">
    <SearchHead :tag="tagName" @updateVal="doQuery" />
    <el-table :data="tabalData" stripe style="width: 100%">
      <el-table-column type="index" label="排名" width="130"> </el-table-column>
      <el-table-column prop="phoneNumber" label="手机号码" width="160">
      </el-table-column>
      <el-table-column prop="num" label="购买数量" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BuyNumQuery, DownloadBuyNum } from "@/api/module/dashboard";
import SearchHead from "./SearchHead.vue";

export default {
  name: "Num",
  data() {
    return {
      tagName: "5",
      tabalData: [],
    };
  },
  components: {
    SearchHead,
  },
  created() {},
  mounted() {
    this.doQuery();
  },
  methods: {
    async doQuery(val) {
      if (val == undefined || val == "") {
        val = 10;
      }
      const r = await BuyNumQuery(val);
      this.tabalData = r;
    },
  },
};
</script>

<style lang="less" scoped>
.component {
  overflow: hidden;
  .el-table {
    padding: 0 20px 15px;
  }
}
</style>