<template>
  <div class="component">
    <SearchHead :tag="tagName" @update="doQuery" />
    <div id="newchartDay" class="chart">暂无数据</div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { DayUserQuery } from "@/api/module/dashboard";
import SearchHead from "./SearchHead.vue";

let option = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
  },
  grid: {
    x: 40,
    x2: 40,
    y: 30,
    y2: 24,
  },
  legend: {
    top: -5,
    right: 30,
    textStyle: {
      fontSize: 10,
      color: "#060b17",
    },
    icon: "roundRect",
    itemWidth: 10,
    itemHeight: 10,
    shadowColor: "rgba(255, 255, 255, .5)",
    shadowBlur: 5,
    shadowOffsetX: 5,
    shadowOffsetY: 5,
  },
  xAxis: {
    type: "category",
    data: ["2021-06", "2021-07", "2021-08", "2021-09", "2021-10", "2021-11"],
    boundaryGap: false,
    axisLabel: {
      fontSize: 10,
      color: "#7b7b7b",
    },
    // 不显示x轴线
    axisLine: {
      show: true,
      symbol: ["none", "arrow"],
      symbolOffset: [0, 7],
      symbolSize: [10, 10],
      lineStyle: {
        color: "#73a3d5",
        width: "2",
      },
    },
    // 不显示x轴刻度
    axisTick: {
      show: true,
    },
  },

  yAxis: {
    type: "value",
    axisLabel: {
      fontSize: 10,
      color: "#7b7b7b",
      formatter: "{value}",
    },
    axisTick: {
      show: true,
    },
    axisLine: {
      show: true,
      symbol: ["none", "arrow"],
      symbolOffset: [0, 7],
      symbolSize: [10, 10],
      lineStyle: {
        color: "#73a3d5",
        width: "2",
      },
    },
    // splitLine: {
    //   show: true,
    //   lineStyle: {
    //     color: "#15768F",
    //   },
    // },
  },
  series: [
    {
      name: "实名用户",
      type: "line",
      data: [],
      lineStyle: {
        color: "#6399d2",
        width: 3,
      },
      symbol: "circle",
      itemStyle: {
        borderWidth: 4,
        borderColor: "#71a3e0",
        color: "#71a3e0",
      },
    },
    {
      name: "注册用户",
      type: "line",
      data: [],
      lineStyle: {
        color: "#e4833d",
        width: 3,
      },
      symbol: "circle",
      itemStyle: {
        borderWidth: 4,
        borderColor: "#e4833d",
        color: "#e4833d",
      },
    },
  ],
  color: ["#e4833d", "#6399d2"],
};

export default {
  name: "DayUser",
  data() {
    return {
      chart: {},
      splitNumber: 12,
      tagName: "1",
      timeArr: [],
    };
  },
  components: {
    SearchHead,
  },
  created() {},
  mounted() {
    this.chart = echarts.init(document.getElementById("newchartDay"));
    this.doQuery();
  },
  beforeDestroy() {
    this.chart.clear();
  },
  methods: {
    async doQuery(val) {
      let data;
      if (val != null || val != undefined) {
        let [startTime, endTime] = val;
        data = {
          startTime,
          endTime,
        };
      }
      const r = await DayUserQuery(data);
      if (r.code != 0) return;
      if (r.data.authList != null && r.data.registerList != null) {
        let authList = r.data.authList;
        let registerList = r.data.registerList;
        this.loadChart(authList, registerList);
      }
    },
    loadChart(authList, registerList) {
      let xAxisData = [];

      let y1AxisData = [];
      let y2AxisData = [];
      authList.forEach((item, index) => {
        xAxisData.push(item.date);
        y1AxisData.push(item.num);
      });
      registerList.forEach((item, index) => {
        y2AxisData.push(item.num);
      });
      //更新x,y轴数据
      this.$nextTick(function () {
        let newOption = JSON.parse(JSON.stringify(option));
        newOption.xAxis.data = xAxisData;
        newOption.series[0].data = y1AxisData;
        newOption.series[1].data = y2AxisData;

        console.log("newOption", newOption);
        this.chart.setOption(newOption);
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.component {
  overflow: hidden;
  .component-nav {
    width: 100px;
    height: 80px;
  }
}

.chart {
  height: 77%;
}
</style>