import { get, post, post1 } from "@/api/config";
// 基础数据
export const BaseQuery = () => get('v1/dashBoard/base', {
    headers: { 'Cache-Control': 'no-cache' }
});

export const DayUserQuery = (data) => post('v1/dashBoard/regAndAut/day', data);
export const MonthUserQuery = (data) => post('v1/dashBoard/regAndAut/month', data);
export const DaySaleQuery = (data) => post('v1/dashBoard/sale/numAndAmount/day', data);
export const MonthSaleQuery = (data) => post('v1/dashBoard/sale/numAndAmount/month', data);

// 折线图导出的接口
// export const DownLoadTag = (data) => post1('v1/dashBoard/tag/download', data, { responseType: "blob", });



export const BuyAmountQuery = (size) => get(`v1/dashBoard/buyAmountList?size=${size}`);
// export const BuyAmountDownLoad = (size) => get(`v1/dashBoard/buyAmountList/download`,);

export const BuyNumQuery = (size) => get(`v1/dashBoard/buyNumList?size=${size}`);

export const InviteQuery = (size, type) => get(`v1/dashBoard/inviteList?size=${size}&sortType=${type}`);



export const DownloadBuyNum = (size) => get(`v1/dashBoard/buyNumList/download?size=${size}`, {
    headers: {
        responseType: "blob",
    }
});

